module.exports = {
    HeaderMessage: {
        companyTitle: 'CINO Recycling',
        companyDescript: 'Your Reliable Recycling Partner',
    },
    HeaderNav: {
        HeaderIndex: 'HOME',
        HeaderAbout: "ABOUT US",
        HeaderProfess: "Our Certifications",
        HeaderMyCustomer: 'Our Clients',
        HeaderSuccessSample: 'Success Stories',
        HeaderprofessServe: "SERVICES",
        HeaderIt: "IT Asset Disposition",
        HeaderSafety: 'Data Security and Destruction',
        HeaderPhy: 'Logistic Management',
        headerWP:'CLOUD'

    },
    ContainerMessage: {
        ContainerTitle: 'Convenient, Reliable',
        ContainerTitle2: 'Electronics Recycling For Businesses.',
        ContainerDescript1: 'CINO Recycling is the leading environmental solutions provider in China, serving more ',
        ContainerDescript2:'than 500+ commercial and industrial customers all over China. We have invested',
        ContainerDescript3:'in developing electronic wastesolutions for a changing world. Today, ',
        ContainerDescript4:'this includes not just disposal and recycling, but keep your sensitive data secure to help our',
        ContainerDescript5:'customers achieve their green goals, including zero waste.',
        ContainerMoreAbout:'More',
        ContainerReasion:'Why choose CINO Recycling',
        ContainerReasion1:'1.As the leading provider of electronic waste management services in China, Cino Recycling has extensive experience in managing materials responsibly and sustainably. We\'ll not only ensure all electronics you entrust to us are wiped clean of any lingering data, we\'ll identify parts that may be remarketed or recycled to minimize your costs. Some of our clients even achieve a neutral or revenue-generating status for their asset retirement programs.',
        ContainerReasion2:'2.We know how important trust is in business. When you trust your electronics recycling to us, protecting your reputation becomes part of maintaining our own.',
        ContainerBelieve:'TRUSTED',
        ContainerLeader:'An industry leader with proven experience in environmental electronic waste management.',
        ContainerReliable:'DEPENDABLE',
        ContainerReliable1:'Work with a single management team thats easy to reach and provides personal service.',
        ContainerFlow:'TRANSPARENCY',
        ContainerFlow1:'Track progress of your eCycling in real time on the cloud and receive comprehensive documentation for your records.',
        ContainerEfficient:'EFFICIENT',
        ContainerEfficient1:'Expert staff and partnerships throughout the country mean we get the job done fast and effectively.',
        ContainerSpeedy:'CONVENIENT',
        ContainerSpeedy1:'Simple online registration to get new customers started, and an easy add-on for current CINO Recycling clients.',
        ContainerProfessionAccept:'CERTIFIED',
        ContainerProfessionAccept1:'Awarded the most respected industry certifications, including R2/RIOS, ISO\'s 9001,14001 and China Recycling Cert.',
        ContainerServeGood: 'Our Services',
        ContainerItManage:' IT Asset Disposition',
        ContainerIt1:'​WE DO ITAD RIGHT!',
        ContainerIt2:'We will be your one-stop IT Asset Disposition(ITAD) partner. Our innovative end-to-end solutions include asset retirement, data destruction, tracking, and management. We meticulously plan and streamline the entire ITAD process, so you don’t need to run after multiple vendors.',
        ContainerIt3:'More',
        ContainerDataSafter:' Data Security and Destruction',
        ContainerDataSafter1:'100% PROTECTION! 100% SECURE!',
        ContainerDataSafter2:'CINO Recycling is a responsible IT Asset Disposition leader with world-class expertise and tools to handle 100% secure Data Security, Destruction and Sanitization for all types of data storage devices.',
        ContainerDataSafter3:'More',
        ContainerFlowManage:' LOGISTIC MANAGEMENT',
        ContainerFlowManage1:'​ANY QUANTITY! ANYWHERE!',
        ContainerFlowManage2:'CINOs Logistics team uses efficient resources – right from packing at your end to our facility for processing, swiftly and securely.',
        ContainerFlowManage3:'Our logistics solutions include:',
        ContainerFlowManage4:'Dedicated Lifespan trucks for pick-up Customized all-weather shipping',
        ContainerFlowManage5:'containers Shipping totes and bins',
        ContainerFlowManage6:'Trusted partnerships with white-glove logistic providers',
        ContainerFlowManage7:'Chain of Custody services – efficient',
        ContainerFlowManage8:'equipment tracking system',
        ContainerFlowManage9:'More',

    },
    BottomMessage:{
        BottomCallAbout:'Contact Us',
        BottomPhone:'Serveice Hotline',
        BottomEmail:'Service Email',
        BottomWchart:'Official WeChart',
        BottomAddress:'Address: No. 850 Changjiang West Road, Baoshan District, Shanghai',
        BottomEweima:'Follow Siam environmental protection official WeChat ID for more information and services',
        BottomTime:'2017-',
        BottomCompany:'CINO Recycling Co., LTD. | ',
        BottomCompany00:' 沪 ICP备 2020026590 号',
        BottomCompany01:'(All rights reserved)',
    },
    AboutMessage: {
        About1: 'About CINO Recycling',
        About2: 'CINO Recycling is the leading environmental solutions provider in China, serving more than 500+ commercial and industrial customers all over China. We have invested in developing electronic waste solutions for a changing world. Today, this includes not just disposal and recycling, but keep your sensitive data secure to help our customers achieve their green goals, including zero waste.',
        About3: 'We help businesses dispose of electronics in a way thats safe, environmentally responsible, and in compliance with government mandates for electronics recycling.',
        About4: 'As China’s leading provider of electronic waste management services, our mission is to maximize resource value while minimizing impact in order to further both economic and environmental sustainability for all of our stakeholders.',
        About5: 'OUR ADVANTAGES',
        About6: 'CINO Recycling’s expert ITAD team, combined with our wide network, gives you the following advantages:',
        About7: 'Lower cost of transportation and logistics across China',
        About8: 'Lower carbon footprint',
        About9: '100% data security',
        About10: 'Dedicated client representative to address your requirement',
        About11: '5.Effective hardware resale and investment recovery programs',

    },
    MyCustomerMessage: {
        MyCustomer1: 'Our Clients',
        MyCustomer2: 'Valued Partnerships',
        MyCustomer3: 'Starbucks',
        MyCustomer4: 'adidas',
        MyCustomer5: 'Under Armour',
        MyCustomer6: 'Puma',
        MyCustomer7: 'Sephora',
        MyCustomer8: 'Pwc',
        MyCustomer9: 'Pernod Ricard',
        MyCustomer10: 'Publicis Group',
        MyCustomer11: 'China Eastern',
        MyCustomer12: 'BMW',
        MyCustomer13: 'General Mills',
        MyCustomer14: 'Solenis',
    },
    PhysicalServeMessage:{
        PhysicalServe1:'LOGISTIC MANAGEMENT',
        PhysicalServe2:'Logistics Management',
        PhysicalServe3:'Ours Logistics team uses efficient resources – right from packing at your end to our facility for processing, swiftly and securely.',
        PhysicalServe4:'Our logistics solutions include:',
        PhysicalServe5:'Dedicated Lifespan trucks for pick-up',
        PhysicalServe6:'Customized all-weather shipping containers',
        PhysicalServe7:'Shipping totes and bins',
        PhysicalServe8:'Trusted partnerships with white-glove logistic providers',
        PhysicalServe9:'Chain of Custody services – efficient equipment tracking system',
    }
}
