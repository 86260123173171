<template>
  <div id="app" data-server-rendered="true">
      <router-view></router-view>
  </div>
</template>

<script>


export default {

  name: 'App',
  components: {

  }
}
</script>

<style>


</style>
