module.exports = {
    HeaderMessage: {
        companyTitle: '暹诺环保',
        companyDescript: '值得信赖的电子回收合作伙伴',
    },
    HeaderNav: {
        HeaderIndex: '首页',
        HeaderAbout: "公司介绍",
        HeaderProfess: "资格证书",
        HeaderMyCustomer: '我们的客户',
        HeaderSuccessSample: '成功案例',
        HeaderprofessServe: "专业服务",
        HeaderIt: "IT资产处理",
        HeaderSafety: '数据安全与销毁',
        HeaderPhy: '仓储物流服务',
        headerWP:'cino网盘'
    },
    ContainerMessage: {
        ContainerTitle: '暹诺环保是一家领先、便捷、',
        ContainerTitle2: '值得信赖的电子产品回收企业。',
        ContainerDescript1: '暹诺环保(CINO Recycling)是中国领先的环境解决方案提供商，',
        ContainerDescript2: '服务于中国超过500多家客户。我们提供电子废弃物解决方案',
        ContainerDescript3: '以适应不断变化的市场需求。目前该领域不仅包括处理和回收，',
        ContainerDescript4: '还包括确保敏感数据的安全，以帮助我们的客户实现零废物的绿色目标。',
        ContainerDescript5: '',
        ContainerMoreAbout: '更多信息',
        ContainerReasion: '选择我们的理由',
        ContainerReasion1: '1.作为中国领先的电子废弃物管理服务提供商，暹诺环保(CINO Recycling)在负责任和可持续管理材料方面拥有丰富的经验。我们不仅会确保您委托给我们的所有电子产品都被清除掉任何残留的数据，我们还会识别出可以重新出售或回收的部件，以最大限度地降低您的成本。我们的一些客户甚至为他们的资产报废计划取得了收入。',
        ContainerReasion2: '2.我们知道信任在商业中有多重要。当您将报废电子产品交给我们的同事，保护您的声誉就成了维护我们自己的一部分。',
        ContainerBelieve: '值得信赖',
        ContainerLeader: '我们是电子废弃物回收管理方面拥有成熟经验的行业领导者.',
        ContainerReliable: '可靠',
        ContainerReliable1: '我们独立的管理团队，更便于统一管理.',
        ContainerFlow: '流程透明',
        ContainerFlow1: '在云端实时跟踪您的电子循环的进度，并接收完整的文档以供记录.',
        ContainerEfficient: '​高效',
        ContainerEfficient1: '我们专业的员工以及遍及全国各地的合作伙伴使我们能快速有效地完成各项工作.',
        ContainerSpeedy: '便捷',
        ContainerSpeedy1: '新客户开始简单的在线注册就能成为我们的客户.',
        ContainerProfessionAccept: '专业认证',
        ContainerProfessionAccept1: '我们获得业内普遍认可的资质和认证，包括R2/RIOS、ISO 9001、ISO 40001以及国内再生资源回收认证.',
        ContainerServeGood: '专业服务',
        ContainerItManage: ' IT资产处置',
        ContainerIt1: '​安全、便捷、合规',
        ContainerIt2: '我们将成为您的一站式IT资产处置(ITAD)合作伙伴。我们创新的端到端解决方案包括资产回收、数据销毁、跟踪和管理。我们精心规划并简化了整个IT资产处置流程，因此您不再需要多个供应商和由此造成的沟通成本。',
        ContainerIt3: '更多信息',
        ContainerDataSafter: ' 数据安全和销毁',
        ContainerDataSafter1: '100% 可靠! 100% 安全!',
        ContainerDataSafter2: '作为一家负责任的IT资产处置领导者，我们拥有世界一流的专业知识和设备，可为所有类型的数据存储设备提供100%安全的数据安全、销毁和消除处理。',
        ContainerDataSafter3: '更多信息',
        ContainerFlowManage: ' 物流管理',
        ContainerFlowManage1: '​任何数量！任何地点！',
        ContainerFlowManage2: '我们的物流团队使用高效的资源快速安全的打包您的设备。',
        ContainerFlowManage3: '我们的物流解决方案包括：',
        ContainerFlowManage4: '专用物流车队',
        ContainerFlowManage5: '运输定制集装箱',
        ContainerFlowManage6: '手提箱及存储箱',
        ContainerFlowManage7: '可靠的物流供应商合作关系',
        ContainerFlowManage8: '监管链服务—高效的设备跟踪系统',
        ContainerFlowManage9: '更多信息',
    },
    BottomMessage: {
        BottomCallAbout: '联系我们',
        BottomPhone: '服务热线',
        BottomEmail: '服务电邮',
        BottomWchart: '官方微信',
        BottomAddress: '地址:上海市宝山区长江西路850号',
        BottomEweima: '扫描上方二维码关注暹诺环保官方微信号获取更多资讯和服务',
        BottomTime: '2017-',
        BottomCompany: '上海暹诺环保科技有限公司  |   ',
        BottomCompany00:'沪  ICP备2020026590号',
        BottomCompany01: '(版权所有，违者必究)',
    },
    AboutMessage: {
        About1: '公司简介',
        About2: '暹诺环保(CINO Recycling)是中国领先的环境解决方案提供商，服务于中国超过500多家客户。我们提供电子废物解决方案，以适应不断变化的市场需求。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全，以帮助我们的客户实现零废物的绿色目标。',
        About3: '我们帮助企业以安全、环保的方式处理电子产品，并遵守政府关于电子产品回收的相关规定。',
        About4: '作为中国领先的电子废物管理服务提供商，我们的使命是最大限度地提高资源价值，同时最大限度地减少影响，以进一步促进我们所有利益相关者的经济和环境可持续性。',
        About5: '我们的核心优势',
        About6: '我们的专业IT资产处置团队结合我们广泛的合作伙伴网络，为您提供以下优势:',
        About7: '降低整个运输和物流成本',
        About8: '低碳足迹',
        About9: '100%数据安全',
        About10: '专用的客户代表满足您的要求',
        About11: '',

    },
    ProfessionMessage: {},

    MyCustomerMessage: {
        MyCustomer1: '我们的客户',
        MyCustomer2: 'Valued Partnerships',
        MyCustomer3: '星巴克',
        MyCustomer4: '阿迪达斯',
        MyCustomer5: '安德玛',
        MyCustomer6: '彪马',
        MyCustomer7: '丝芙兰',
        MyCustomer8: '普华永道',
        MyCustomer9: '保乐力加',
        MyCustomer10: '阳狮集团',
        MyCustomer11: '东方航空',
        MyCustomer12: '宝马',
        MyCustomer13: '通用磨坊',
        MyCustomer14: '索灵思',
    },
    PhysicalServeMessage:{
        PhysicalServe1:'仓储物流服务',
        PhysicalServe2:'Logistics Management',
        PhysicalServe3:'Ours Logistics team uses efficient resources – right from packing at your end to our facility for processing, swiftly and securely.',
        PhysicalServe4:'Our logistics solutions include:',
        PhysicalServe5:'Dedicated Lifespan trucks for pick-up',
        PhysicalServe6:'Customized all-weather shipping containers',
        PhysicalServe7:'Shipping totes and bins',
        PhysicalServe8:'Trusted partnerships with white-glove logistic providers',
        PhysicalServe9:'Chain of Custody services – efficient equipment tracking system',
    }



}