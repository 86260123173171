import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)


const Index = () => import('../view/layout/index')
const About = () => import('../view/layout/children/About')
const Profession = () => import('../view/layout/children/Profession')
const SuccessSample = () => import('../view/layout/children/SuccessSample')
const MyCustomer = () => import('../view/layout/children/MyCustomer')
const ProfessionServe = () => import('../view/layout/serve/ProfessionServe')
const Itciorecycle = ()=> import('../view/layout/serve/Itciorecycle')
const PhysicalServe =()=> import('../view/layout/serve/PhysicalServe')
const SafetyAndDestroy = () =>import('../view/layout/serve/SafetyAndDestroy')
const MoreOther = () => import('../view/layout/moreother/MoreOther')

const routes = [
    {
        path: '',
        redirect: '/index'
    },
    {
        path: '/index',
        component: Index
    },
    {
        path:'/about',
        component:About
    },
    {
        path:'/profession',
        component:Profession
    },
    {
        path:'/successsample',
        component:SuccessSample
    },
    {
        path:'/mycustomer',
        component:MyCustomer
    },
    {
        path:'/professionserve',
        component:ProfessionServe
    },
    {
        path:'/itciorecycle',
        component:Itciorecycle
    },
    {
        path:'/physicalserve',
        component:PhysicalServe
    },
    {
        path:'/safetyAnddestroy',
        component:SafetyAndDestroy
    },
    {
        path:'/moreother',
        component:MoreOther
    }








]


const router = new VueRouter({
    routes,
    mode:"history"
})

export default router