import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    // set './app.js' => 'app'
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})

const store = new Vuex.Store({
    modules,
    getters,
    // 解决刷新vuex状态丢失问题
    plugins: [
        createPersistedState({
            key: 'localLanguage',
            storage: {
                getItem: key => window.sessionStorage.getItem(key),
                setItem: (key, value) => window.sessionStorage.setItem(key, value),
                removeItem: key => window.sessionStorage.removeItem(key)
            }
        })
    ]
})

export default store