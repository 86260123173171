const language = {
    /* 用户基本信息 */
    state: {
        localLanguage: 'en',
    },
    /* 属性值设置 */
    mutations: {
        SET_LOCAL_LANGUAGE: (state, localLanguage) => {
            state.localLanguage = localLanguage
        },
    },
    /* 操作 */
    actions: {
        /**
         * 設置 localLanguage
         * @param commit
         * @param localLanguage
         * @constructor
         */
       SetLocalLanguage({commit}, localLanguage) {
           commit('SET_LOCAL_LANGUAGE', localLanguage)
       },
        ClearLanguage({commit}) {
            commit('SET_LOCAL_LANGUAGE', '')
        }
    }
}
export default language