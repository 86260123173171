import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/store";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import VueI18n from "vue-i18n";
import i18n from "./i18n/i18n";
/*图片放大工能*/
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'




import './assets/css/global.css'
import './assets/css/base.css'
import MetaInfo from 'vue-meta-info'
Vue.use(Viewer)


Viewer.setDefaults({

  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }

})

Vue.use(ElementUI)

Vue.use(VueDirectiveImagePreviewer)

// Vue.use(VueI18n)
Vue.use(MetaInfo)

/*界面最顶部*/


router.afterEach((to,from,next)=>{

  window,scrollTo(0,0)

})

Vue.config.productionTip = false
Vue.prototype.localLanguage =      {
  name: 'zh',
  icon: 'https://static.parastorage.com/services/linguist-flags/1.363.0/assets/flags/round/CHN.svg',
  title: '中文'
}
Vue.use(mavonEditor)
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
},
}).$mount('#app')
